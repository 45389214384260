th,
td {
  text-align: center;
  padding: 10px;
}

table {
  width: 90%;
  margin: 0 auto;
}

h1 {
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1em;
}

body {
  background-color: bisque;
  width: 100%;
}

main {
  padding: 20px;
  margin-top: 40px;
}

.navbar {
  background-color: darkgoldenrod;
}

table {
  width: 100%;
}

#MyModification {
  position: fixed;
  width: 100%;
  margin-top: -2.5em;
  z-index: 7;
  padding-left: 10px;
  padding-left: 20px;
}
.space-around {
  justify-content: space-around;
}

/*Mettre les images selon la taille de l'écran */
@media screen and (min-width: 10px) and (max-width: 750px) {
  img {
    width: 100%;
  }
  .imageVerticale {
    height: 300px;
  }
  .imageHorizontale img {
    width: 100%;
  }
  .imageHorizontale {
    height: 300px;
    align-items: center;
  }
  .imageVerticale img {
    height: 100%;
    width: auto;
  }
  .imageJeu2 {
    margin-top: 10px;
  }
  .elementFlexibleCustom {
    text-align: center;
    flex-grow: 1;
  }
  .myParagraph {
    padding: 0 20px;
  }
}
@media screen and (min-width: 751px) and (max-width: 40000px) {
  img {
    height: 300px;
    width: auto;
  }
  .imageJeu2 {
    cursor: pointer;
    width: 200px;
    height: auto;
    margin: 10px;
  }
  .pictureAdmin {
    width: 150px;
    height: auto;
  }
  .pictureEvent{
    width: 300px;
    height: auto;
  }
  .elementFlexibleCustom {
    text-align: center;
    flex-grow: 1;
    width: 40%;
  }
}

.buttonConnexion {
  color: white;
  background-color: #11ffee00;
  border: 0 solid black;
  padding: 5px 0;
}

.containerFlexible {
  display: flex;
  flex-wrap: wrap;
}

.center {
  justify-content: center;
}

.elementFlexible {
  text-align: center;
  flex-grow: 1;
}

.myButton {
  background-color: Transparent;
  border: none;
  width: 100%;
}

.clignote {
  color: hsl(324, 70%, 45%);
  animation: wheelHueColor 3s linear infinite;
}

.email {
  font-size: x-large;
}

.frontCard {
  display: block;
}

.turnImage {
  transform: rotateY(180deg);
  transition: transform 5s;
}

.backCard {
  display: none;
}

@keyframes wheelHueColor {
  from,
  to {
    color: hsl(324, 70%, 45%);
  }
  10% {
    color: hsl(360, 65%, 45%);
  }
  20% {
    color: hsl(36, 80%, 45%);
  }
  30% {
    color: hsl(72, 75%, 35%);
  }
  40% {
    color: hsl(108, 65%, 35%);
  }
  50% {
    color: hsl(144, 75%, 35%);
  }
  60% {
    color: hsl(180, 75%, 35%);
  }
  70% {
    color: hsl(216, 60%, 45%);
  }
  80% {
    color: hsl(252, 65%, 50%);
  }
  90% {
    color: hsl(288, 60%, 40%);
  }
}


.moreSize
{
  font-size: 3em;
}

.myIcon {
  background-color: whitesmoke;
  border-radius: 45px;
  margin: 5px;
  padding: 3px;
  cursor: pointer;
}

.float {
  text-align: center;
}

.image-upload>input {
  display: none;
}

.linkEvent {
  color: black;
  text-decoration: none;
}

/* Ces lignes permettent d'enlever le control sur le carousel.
.myCarousel .carousel-control-next-icon, .myCarousel .carousel-control-prev-icon {
  display: none;
}*/
